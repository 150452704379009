.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open-overflow-hidden {
  overflow: hidden !important;
}
